<template>
  <v-row class="loginRow" align="center" justify="center">
    <v-col cols="12" sm="8" md="4" class="loginCol">
      <div class="bell--element">
        <img src="@/assets/bell-second.png" />
      </div>
      <div class="letter--element">
        <img src="@/assets/letter.png" />
      </div>
      <v-card style="border-radius: 10px;" class="loginCard">
        <div>
          <img src="@/assets/big-logo.png" />
        </div>
        <v-card-text v-if="!resetPasswordScreen">
          <v-form class="loginForm" @submit.prevent="tryLogin">
            <v-text-field
              @keypress.enter="tryLogin"
              filled
              rounded
              :class="!$vuetify.breakpoint.smAndDown && 'login--input--field'"
              type="email"
              v-model="email"
              placeholder="Email"
              required
              :rules="emailRules"
              background-color="#e9e9e9"
              height="81"
              x-large
            />
            <v-text-field
              height="81"
              :class="!$vuetify.breakpoint.smAndDown && 'login--input--field'"
              @keypress.enter="tryLogin"
              filled
              rounded
              dense
              v-model="password"
              placeholder="Password"
              required
              background-color="#e9e9e9"
              type="password"
              x-large
            />
            <div class="resetNavigation">
              <p @click="resetPasswordScreen = true">Forgot password?</p>
            </div>
            <v-btn
              height="77"
              x-large
              class="loginBtn"
              @click.prevent="tryLogin"
              >Log in</v-btn
            >
          </v-form>
          <p v-if="feedback" class="text-uppercase red--text">{{ feedback }}</p>
        </v-card-text>
        <div v-else>
          <div v-if="mailFeedback" class="text-uppercase recoveryFeedback">
            <p class="text-uppercase">{{ mailFeedback }}</p>

            <div class="resetNavigation">
              <p @click="resetPasswordScreen = false">Back to login</p>
            </div>
          </div>

          <v-form class="loginForm" @submit.prevent="recoveryAction" v-else>
            <v-text-field
              @keypress.enter="recoveryAction"
              filled
              rounded
              :class="!$vuetify.breakpoint.smAndDown && 'login--input--field'"
              type="email"
              v-model="recoveryEmail"
              placeholder="Email"
              required
              :rules="emailRules"
              background-color="#e9e9e9"
              height="81"
              x-large
            />
            <div class="resetNavigation">
              <p @click="resetPasswordScreen = false">Back to login</p>
            </div>
            <v-btn
              height="77"
              x-large
              class="loginBtn"
              @click.prevent="recoveryAction"
              >Send recovery mail</v-btn
            >
          </v-form>
        </div>
        <div class="login-slogan">
          <h3>HR is a work of <span>♥</span></h3>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline"
            >ISIKUANDMETE TÖÖTLEMISE REEGLID VOLITATUD TÖÖTLEJALE</span
          >
        </v-card-title>
        <GDPRtext></GDPRtext>
        <div v-if="feedback">
          <v-container>
            <v-alert border="right" colored-border type="error" elevation="2"
              >Please accept terms and conditions to access candidates.</v-alert
            >
          </v-container>
        </div>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="confirmation"
          label="I agree with terms and conditions"
        ></v-checkbox>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="DeclineGDPR"
            >Disagree</v-btn
          >
          <v-btn color="green darken-1" text @click="AcceptGDPR">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import GDPRtext from "@/components/GDPRtext";

export default {
  name: "login",
  data() {
    return {
      dialog: false,
      confirmation: false,
      show: false,
      email: "",
      password: "",
      feedback: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      resetPasswordScreen: false,
      recoveryEmail: "",
      mailFeedback: "",
    };
  },
  components: {
    GDPRtext,
  },
  methods: {
    ...mapActions(["login", "setError"]),
    async recoveryAction() {
      try {
        await axios.post("/api/recovery/requestResetPassword", {
          email: this.recoveryEmail,
        });
        this.mailFeedback = "Password recovery link sent to email";
        this.recoveryEmail = "";
      } catch (error) {
        this.setError("Failed to send recovery email");
      }
    },
    async AcceptGDPR() {
      try {
        if (this.confirmation === true) {
          await axios.post("/api/user/GDPR", { email: this.userEmail });
          this.$router.push("/campaign/" + this.defaultCampaign);
        } else {
          this.feedback = "Confirmation box isnt checked";
        }
      } catch (error) {
        this.feedback = "error accepting";
      }
    },
    DeclineGDPR() {
      location.reload();
    },
    async tryLogin() {
      try {
        const email = this.email;
        const password = this.password;
        await this.login({ email, password });
        if (this.GDPRcheck === false) {
          return (this.dialog = true);
        }
        if (this.GDPRcheck === true) {
          this.$router.push("/campaign/" + this.defaultCampaign);
        }
      } catch (error) {
        this.feedback = "Login failed: Invalid username or password.";
      }
    },
  },
  mounted() {
    if (this.loggedIn && this.GDPRcheck) {
      this.$router.push("/campaign/" + this.defaultCampaign);
    }
  },
  computed: {
    ...mapGetters(["defaultCampaign", "GDPRcheck", "loggedIn", "GDPRcheck"]),
    userEmail() {
      return this.$store.state.user.email;
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  font-family: "Nunito", sans-serif;
}
.letter--element {
  position: absolute;
  left: -70px;
  bottom: -20px;
}
.bell--element {
  position: absolute;
  top: -70px;
  right: -30px;
}
.loginCol {
  position: relative;
}
.login-slogan {
  padding-bottom: 25px;
  h3 {
    font-size: 24px;
    font-weight: 400;
    color: #bababa;
  }
  span {
    color: blue;
    font-size: 25px;
  }
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: gray;
}

.loginRow {
  height: 100vh;
  width: 100vw;
  img {
    max-width: 150px;
    margin-top: 20px;
  }
}
.v-input--checkbox {
  justify-content: center;
}
.v-card__actions {
  display: grid;
  grid-auto-flow: column;
}
.loginCard {
  height: 100%;
  width: 100%;
  z-index: 2;
  padding-right: 5%;
  padding-left: 5%;
  background: transparent padding-box 0% 0% no-repeat padding-box;
  border: 1px solid #bababa3c;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(5px);
  max-width: 540px;
  position: relative;
  img {
    width: 150px;
    max-width: 150px;
  }
}
.resetNavigation {
  display: flex;
  justify-content: flex-end;
  p {
    cursor: pointer;
    color: #bababa;
    font-size: 16px;
    &:hover {
      color: #604fe2;
    }
  }
}
.loginForm {
  height: 100%;
  padding-bottom: 10%;
  padding-top: 10%;
}
.loginBtn {
  box-shadow: 0px 5px 15px #0000000f;
  border-radius: 39px;
  background: linear-gradient(259deg, #604fe2 0%, #772276 100%) 0% 0% no-repeat
    padding-box;
  color: #ffffff;
  width: 100%;
  justify-self: end;
  font-size: 24px;
  font-weight: 900;
}
@media only screen and (max-width: 600px) {
  .backImage {
    width: 100%;
  }
  .loginRow {
    margin-right: 0;
    overflow: hidden;
  }
}
.login--input--field {
  border-radius: 40px;
  font-size: 24px;
  color: #bababa;
}
.recoveryFeedback {
  p {
    font-size: 24px;
  }
}
</style>
